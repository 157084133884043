import { VideoPlayerList } from '@front/shared/ds';
import { VideoCollection, Section } from '@shared/master-types';
import React from 'react';

export type TVideoPlayerListConnectedProps = Extract<
  Required<Section>['rows'][number],
  {
    blockType: 'video-player-list';
  }
>;

export const VideoPlayerListConnected: React.FC<
  TVideoPlayerListConnectedProps
> = ({ selectedVideos, title }) => {
  return (
    <VideoPlayerList
      listTitle={title}
      selectedVideos={selectedVideos as VideoCollection[]}
    />
  );
};

export default VideoPlayerListConnected;
